#upgrade {
    padding: 4rem 1rem;
}

#upgrade ul li > p {
    display: inline;
}

#upgrade ul li > strong {
    margin-right: 1rem;
    font-weight: 700;
}

#upgrade ul {
    padding-inline-start: 0;
    list-style: none;
}

#upgrade dl dt {
    width: fit-content;
}
#upgrade dl dd {
    margin-left: 10rem;
}

#offer-poster-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    gap: 2rem;
}

.offer-poster {
    max-width: 50vh;
}
